<template>
	<v-container
		fluid
	>

		<v-row justify="center">
			<v-col cols="12">
				<v-card>
					<v-card-title>
						<v-spacer />
						<Button @click="editForm.show = true">
							<v-icon>mdi-plus</v-icon>
						</Button>
					</v-card-title>

					<v-card-text>
						<v-data-table
							:loading="loading"
							:headers="headers"
							:items="items"
							:server-items-length="totalItems"
							:options.sync="options"
							item-key="id"
							:footer-props="{'items-per-page-options':[10,25,50]}"
						>
							<template v-slot:[`item.action`]="{ item }">
								<v-icon
									small
									class="mr-2"
									@click="deleteItem(item)"
								>
									mdi-delete
								</v-icon>
							</template>
							<template v-slot:[`item.verified`]="{ item }">
								<v-icon v-if="item.verified" color="green">mdi-check-bold</v-icon>
							</template>
							<template v-slot:[`item.published`]="{ item }">
								<v-icon v-if="item.published" color="green">mdi-check-bold</v-icon>
							</template>
						</v-data-table>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>


		<v-dialog v-model="editForm.show" max-width="600px">
			<v-card>
				<v-card-title>
					Add filter
					<v-spacer />
					<Button icon text @click="cancel">
						<v-icon>mdi-close</v-icon>
					</Button>
				</v-card-title>

				<v-divider />

				<v-card-text>
					<v-container>
						<v-form>
							<v-text-field v-model="editForm.form.id" class="d-none"></v-text-field>
							<v-row>
								<v-col cols="12">
									<Input v-model="editForm.form.value" label="Value" required />
								</v-col>
							</v-row>
						</v-form>
					</v-container>
				</v-card-text>
				<v-card-actions>
					<v-spacer />
					<Button type="submit" @click="save">Save</Button>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-container>
</template>

<script>
import { globalMixIn } from '@/App.vue'

export default {
	data: () => ({
		loading: false,
		editForm: {
			show: false,
			form: {
				id: null,
				value: null,
			}
		},
		options: {},
		totalItems: 0,
		headers: [
			{ text: `ID`, value: `id`, sortable: false },
			{ text: `Value`, value: `value`, sortable: false },
			{ text: `Type`, value: `type`, sortable: false },
			{ text: `Actions`, value: `action`, align: `center`, sortable: false },
		],
		items: [],
	}),
	methods: {
		cancel() {
			this.editForm.show = false
		},
		deleteItem(item) {
			this.$root.$confirm(`Delete`, `Are you sure you want to delete ${item.value}?`, { color: `error` })
				.then((confirm) => {
					if (confirm) {
						this.$httpInt.delete(`/v2/news/filters/${item.id}`)
							.then(() => {
								this.$root.$snackbar(`Source deleted`)
								this.refreshContent()
							})
					}
				})
		},
		save() {
			const data = {
				type: `domain`,
				value: this.editForm.form.value,
				hide: 1,
			}
			this.$httpInt.post(`/v2/news/filters/`, data)
				.then(() => {
					this.editForm.show = false
					this.refreshContent()
				})
		},
		refreshContent () {
			this.loading = true
			this.$httpInt.get(`/v2/news/filters/`, { params: this.$paginationParams(this) })
				.then(res => {
					this.items = res.data.result
					this.totalItems = res.data.pagination.total
				})
				.finally(() => this.loading = false)
		},
	},
	mixins: [globalMixIn],
	mounted () {
		this.refreshContent()
	},
	name: `NewsFilter`,
	watch: {
		options: {
			handler () {
				this.refreshContent()
			},
		},
	},
}
</script>
